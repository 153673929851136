import SiteApiProvider from "@/services/SiteApiProvider";

const site_api_provider = new SiteApiProvider();
site_api_provider.setAuthorizationHeadersFromLocal();

const getDefaultState = () => {
  return {
    site_list: [],
    total_site: 0,
    total_page: 0,
    current_page: 0,
    filter_page: null,
    filter_limit: null,
    filter_with_alarm: null,
  };
};

export default {
  state: getDefaultState(),
  getters: {
    site_list_filters(state) {
      let filters = {};
      if (state.filter_page) {
        const key = Object.keys(state.filter_page)[0];
        filters[key] = state.filter_page[key];
      }

      if (state.filter_limit) {
        const key = Object.keys(state.filter_limit)[0];
        filters[key] = state.filter_limit[key];
      }

      if (state.filter_with_alarm) {
        const key = Object.keys(state.filter_with_alarm)[0];
        filters[key] = state.filter_with_alarm[key];
      }
      return filters;
    },
  },
  actions: {
    async requestGetSiteList({ commit, getters }) {
      try {
        const response = await site_api_provider.requestGetSiteList(
          getters.site_list_filters
        );
        if (response.pagination.page == 0) {
          commit("SET_SITES", response.data);
        } else {
          commit("APPEND_SITES", response.data);
        }
        commit("SET_SITE_PAGINATION", response.pagination);
      } catch (error) {
        console.log(error);
      }
    },
    assignSiteFilterOptionToStore({ commit }, filter_option) {
      let filter_params = null;
      switch (filter_option.key) {
        case "page":
          filter_params = filter_option.value
            ? { page: filter_option.value }
            : null;
          commit("SET_FILTER_PAGE", filter_params);
          break;

        case "limit":
          filter_params = filter_option.value
            ? { limit: filter_option.value }
            : null;
          commit("SET_FILTER_LIMIT", filter_params);
          break;

        case "withAlarm":
          filter_params = filter_option.value ? { withAlarm: "1" } : null;
          commit("SET_FILTER_WITH_ALARM", filter_params);
          break;

        default:
          break;
      }
    },
  },
  mutations: {
    SET_SITES(state, payload) {
      state.site_list = payload;
    },
    APPEND_SITES(state, payload) {
      state.site_list = state.site_list.concat(payload);
    },
    SET_FILTER_LIMIT(state, payload) {
      state.filter_limit = payload;
    },
    SET_FILTER_PAGE(state, payload) {
      state.filter_page = payload;
    },
    SET_FILTER_WITH_ALARM(state, payload) {
      state.filter_with_alarm = payload;
    },
    SET_SITE_PAGINATION(state, payload) {
      state.total_site = payload.count;
      state.total_page = payload.total;
      state.current_page = payload.page;
    },
    RESET_SITE_STATE(state) {
      Object.assign(state, getDefaultState());
    },
  },
};
