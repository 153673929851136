<template>
  <v-overlay id="EmptyDataDialog" :opacity="0.5" :value="show_popup">
    <v-dialog v-model="show_popup" persistent max-width="420">
      <v-card class="text-center mx-auto" flat rounded="xl" color="nav_color">
        <v-card-title
          class="red--text text--darken-2 font-weight-bold justify-center"
        >
          Data Not Found
        </v-card-title>
        <v-divider class="mx-4"></v-divider>

        <v-card-text class="mt-4 font-weight-medium">
          Data on your specific query not found in below devices due to data
          lost or another reasons.
        </v-card-text>
        <v-card-text class="font-weight-medium">
          <p v-if="content_popup">
            <span
              v-for="(device_name, index) in content_popup"
              :key="index"
              class="mb-0"
            >
              {{ index + 1 }}. {{ device_name }} <br />
            </span>
          </p>
          <p v-else>-</p>
        </v-card-text>

        <v-divider class="mx-4"></v-divider>

        <v-card-actions class="justify-center">
          <v-btn
            dark
            rounded
            depressed
            color="primary"
            @click.prevent="hideErrorDialog()"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-overlay>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  name: "EmptyDataDialog",
  computed: {
    ...mapState({
      show_popup: (state) => state.device.show_popup_empty_data_list,
      content_popup: (state) => state.device.content_popup_empty_data_list,
    }),
  },
  methods: {
    ...mapMutations({
      hideErrorDialog: "HIDE_EMPTY_DATA_LIST_POPUP",
    }),
  },
};
</script>

<style></style>
