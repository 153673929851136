import DataApiProvider from "@/services/DataApiProvider";

const data_api_provider = new DataApiProvider();
data_api_provider.setAuthorizationHeadersFromLocal();

export default {
  state: {
    selected_predict_params: [],
  },
  actions: {
    async requestSitePredictData({ commit }, filters) {
      try {
        commit("RESET_CHART_STATE");
        commit("SET_CHART_LOADING_STATUS", true);

        let response = await data_api_provider.requestGetPredictData(filters);

        commit("SET_PREDICT_DATA_AXIS");
        commit("SET_PREDICT_DATA_SERIES", response.predict);
        commit("SET_CHART_LOADING_STATUS", false);
      } catch (error) {
        console.log(error);
      }
    },
    async requestRawData(_, filters) {
      try {
        const data = await data_api_provider.requestGetDeviceData(
          filters,
          "raw"
        );
        return data;
      } catch (error) {
        console.log(error);
      }
    },
  },
  mutations: {
    SET_SELECTED_PREDICT_PARAMS(state, payload) {
      state.selected_predict_params = [payload];
    },
    CLEAR_SELECTED_PREDICT_PARAMS(state) {
      state.selected_predict_params = [];
    },
  },
};
