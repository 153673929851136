import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

import HighchartsVue from "highcharts-vue";
Vue.use(HighchartsVue);

import { RecycleScroller } from "vue-virtual-scroller";
import "vue-virtual-scroller/dist/vue-virtual-scroller.css";
Vue.component("RecycleScroller", RecycleScroller);

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.VUE_APP_ANALYTIC_API_KEY,
  authDomain: process.env.VUE_APP_ANALYTIC_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_ANALYTIC_PROJECT_ID,
  storageBucket: process.env.VUE_APP_ANALYTIC_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_ANALYTIC_MSG_SENDER_ID,
  appId: process.env.VUE_APP_ANALYTIC_APP_ID,
  measurementId: process.env.VUE_APP_ANALYTIC_MEASURE_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);

Vue.config.productionTip = false;
Vue.prototype.$analytics = getAnalytics();

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
