<template>
  <v-app>
    <router-view />
    <Error403Dialog />
    <EmptyDataDialog />
  </v-app>
</template>

<script>
import Error403Dialog from "@/components/popups/Error403Dialog";
import EmptyDataDialog from "@/components/popups/EmptyDataDialog";

export default {
  name: "App",
  components: {
    Error403Dialog,
    EmptyDataDialog,
  },
};
</script>

<style>
.light {
  font-weight: 300;
}

.medium {
  font-weight: 600;
}

.disabled-cursor {
  cursor: not-allowed !important;
}

.gradient {
  background: linear-gradient(135deg, #419a90 40%, #3a3a77 80%);
}

.v-btn__content {
  width: 100%;
  white-space: normal;
}

.v-card__text,
.v-list-item__subtitle {
  color: #2c3e50 !important;
}

.rounded-start {
  border-radius: 0.5rem 0 0 0.5rem !important;
}
.rounded-end {
  border-radius: 0 0.5rem 0.5rem 0 !important;
}

.pre-formatted {
  white-space: pre-wrap;
}

.max-scroll {
  overflow: scroll;
  overflow-y: hidden;
  overflow-x: hidden;
  max-height: 180px;
}
</style>
