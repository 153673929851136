import Helpers from "@/utils/Helpers";
import DeviceApiProvider from "@/services/DeviceApiProvider";

const device_api_provider = new DeviceApiProvider();
device_api_provider.setAuthorizationHeadersFromLocal();

export default {
  state: {
    site_devices: [],
    is_site_level: false,
    selected_site_list: [],
    is_custom_timerange: false,
    selected_timerange: null,
    selected_timeframe: null,
    selected_device_types: [],
    selected_device_list: [],
    selected_device_data: [],
    selected_device_data_option: null,
    series_data: [],
    series_data_axis: [],
    show_popup_empty_data_list: false,
    content_popup_empty_data_list: null,
  },
  getters: {
    disable_query(state) {
      if (!state.is_site_level) {
        if (
          state.selected_timerange &&
          state.selected_timeframe &&
          state.selected_device_list.length > 0 &&
          state.selected_device_data.length > 0
        ) {
          return false;
        }
      } else {
        if (state.selected_site_list.length > 0 && state.selected_timerange) {
          return false;
        }
      }
      return true;
    },
    chart_options(state) {
      let options = {
        chart: {
          type: "spline",
          zoomType: "x",
          animation: true,
          style: {
            fontFamily: "Gotham",
          },
        },
        colors: ["#41A791", "#732151", "#E08C16", "#0B8EE0", "#E01716"],
        title: {
          text: "NiXT Care Monitoring Graph",
          style: {
            color: "#2c3e50",
            fontWeight: "bold",
          },
        },
        noData: {
          style: {
            fontWeight: "400",
            fontSize: "18px",
            color: "#C4C4C4",
          },
        },
        xAxis: {
          title: {
            text: "Date/Time",
          },
          type: "datetime",
        },
        yAxis: [],
        credits: {
          enabled: false,
        },
        exporting: {
          enabled: true,
        },
        series: [],
      };

      // Is Site Level ?
      if (state.is_site_level) {
        options.tooltip = {
          shared: true,
          crosshairs: true,
          formatter: function () {
            return Helpers.generateChartTooltipSiteContent(this.points);
          },
        };
        options.chart.type = "column";
        options.plotOptions = {
          series: {
            connectNulls: false,
            turboThreshold: 0,
          },
          column: {
            stacking: undefined,
          },
        };
      } else {
        options.tooltip = {
          shared: true,
          crosshairs: true,
          formatter: function () {
            return Helpers.generateChartTooltipContent(this.points);
          },
        };

        switch (state.selected_timeframe) {
          case "hour":
            options.chart.type = "column";
            options.plotOptions = {
              series: {
                connectNulls: false,
                turboThreshold: 0,
              },
              column: {
                stacking: undefined,
              },
            };
            break;

          case "day":
            options.chart.type = "column";
            options.plotOptions = {
              series: {
                connectNulls: false,
                turboThreshold: 0,
              },
              column: {
                stacking: "normal",
              },
            };
            break;

          default:
            options.plotOptions = {
              series: {
                connectNulls: false,
                marker: {
                  enabled: false,
                },
                turboThreshold: 0,
              },
            };
            break;
        }
      }

      // Appended Y-Axis
      if (state.series_data_axis.length > 0) {
        for (const y_axis of state.series_data_axis) {
          options.yAxis.push(y_axis.payload);
        }
      }

      // Condition : Enable or Disable Export Button
      if (state.series_data && state.series_data.length > 0) {
        options.exporting.enabled = true;
        options.series = state.series_data;
      } else {
        options.exporting.enabled = false;
      }
      return options;
    },
    site_data_filters(state) {
      let filters = {};
      if (state.selected_site_list) {
        const siteIds = state.selected_site_list.map((site) => site._id);
        filters["siteIds"] = siteIds.join(",");
      }

      if (state.selected_timerange) {
        filters["startTime"] = state.selected_timerange.start_time;
        filters["endTime"] = state.selected_timerange.end_time;
      }

      return filters;
    },
    device_data_filters: (state) => (device_type, device_data) => {
      let filters = {};

      if (state.selected_device_list && state.selected_device_list.length > 0) {
        const filtered_device_list = state.selected_device_list.filter(
          (device) => {
            if (Helpers.isMachineType(device_type)) {
              return device.subtype == device_type;
            } else {
              return device.type == device_type;
            }
          }
        );
        if (filtered_device_list && filtered_device_list.length > 0) {
          const device_ids = filtered_device_list.map((device) => device._id);
          filters["deviceIds"] = device_ids.join(",");
        }
      }

      if (device_data) {
        let params = device_data.map((data) => {
          return data.params;
        });
        filters["params"] = params.join(",");
      }

      if (state.selected_timerange) {
        filters["startTime"] = state.selected_timerange.start_time;
        filters["endTime"] = state.selected_timerange.end_time;
      }

      return filters;
    },
    site_list_treeview_format(state, getters, rootState) {
      const root_site_list = rootState.site.site_list;
      if (root_site_list && root_site_list.length > 0) {
        return root_site_list.map((site) => {
          // Initial Tree-view item format
          let tree_item = {
            _id: site._id,
            name: site.siteName,
            children: [],
          };

          // Appended Child: When site id is existing in site_devices
          const found = state.site_devices.find(
            (device) => device.siteId === site._id
          );
          if (found) {
            // RETURN : new mapping object
            const children = found.devices.map((device) => {
              const device_type = device?.deviceType ?? "-";
              if (device_type !== "SER") {
                let device_full_name = device?.nickName ?? "";
                if (device.nickName) {
                  device_full_name += " ( " + device.deviceName + " ) ";
                } else {
                  device_full_name += device.deviceName;
                }

                if (device_type === "-") {
                  device_full_name += " | No Type";
                }

                return {
                  _id: device._id,
                  name: device_full_name,
                  icon: device_type.toLowerCase(),
                  type: Helpers.getDeviceType(device_type),
                  subtype: Helpers.getDeviceSubType(device),
                };
              }
            });

            // ASSIGN Mapped Values
            tree_item.children = children;
          }

          return tree_item;
        });
      }
      return [];
    },
  },
  actions: {
    async requestSiteDataList({ commit, getters }) {
      try {
        commit("SET_CHART_LOADING_STATUS", true);
        commit("CLEAR_OLD_SERIES_DATA");
        let response =
          await device_api_provider.requestGetSitePerformanceRatioData(
            getters.site_data_filters
          );
        let response_data = response.performance;
        commit("SET_DATA_AXIS", response_data);
        commit("SET_SITE_SERIES_DATA", response_data);
        commit("SET_CHART_LOADING_STATUS", false);
      } catch (error) {
        console.log(error);
      }
    },
    async requestDeviceDataRawList({ commit, state, getters }) {
      let empty_data_list = [];
      try {
        commit("SET_CHART_LOADING_STATUS", true);
        commit("CLEAR_OLD_SERIES_DATA");

        let response_list = [];
        for (const device_data of state.selected_device_data) {
          let req_params = getters.device_data_filters(
            device_data.device_type,
            device_data.params
          );

          // DETERMINE : call API based on "selected_timeframe"
          const data = await device_api_provider.requestGetDeviceData(
            req_params,
            state.selected_timeframe
          );

          // CONVERT : query string text to array by separated with comma (,)
          let req_tags = req_params.params.split(",");
          if (state.selected_timeframe === "day") {
            // CONVERT : De-Grouping params in to on-peak + off-peak + total
            if (req_tags.includes("ENEIM")) {
              req_tags = req_tags.filter((item) => item !== "ENEIM");
              req_tags = req_tags.concat([
                "ENEIM_ON",
                "ENEIM_OFF",
                "ENEIM_TOTAL",
              ]);
            }

            if (req_tags.includes("ENEEX")) {
              req_tags = req_tags.filter((item) => item !== "ENEEX");
              req_tags = req_tags.concat([
                "ENEEX_ON",
                "ENEEX_OFF",
                "ENEEX_TOTAL",
              ]);
            }

            if (req_tags.includes("ENETO")) {
              req_tags = req_tags.filter((item) => item !== "ENETO");
              req_tags = req_tags.concat([
                "ENETO_ON",
                "ENETO_OFF",
                "ENETO_TOTAL",
              ]);
            }
          }

          // Look for empty data
          for (const item of data) {
            if (item.data.length === 0) {
              const device_id = item.deviceId;
              const matched = state.selected_device_list.find(
                (item) => item._id === device_id
              );
              empty_data_list.push(matched);
            }
          }

          // ASSIGN to Object
          const response = {
            device_type: device_data.device_type,
            req_tags,
            data,
          };

          response_list = response_list.concat(response);
        }

        commit("SET_DATA_AXIS", response_list);
        commit("SET_DEVICE_SERIES_DATA", response_list);
        commit("SET_CHART_LOADING_STATUS", false);
        if (empty_data_list.length > 0) {
          commit("SHOW_EMPTY_DATA_LIST_POPUP", empty_data_list);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async requestDeviceList({ commit }, filters) {
      const query_string = "?limit=100&siteId=" + filters.id;
      try {
        const response = await device_api_provider.requestGetDeviceList(
          query_string
        );
        commit("SET_SITE_DEVICES", {
          siteId: filters.id,
          devices: response.data,
        });
      } catch (error) {
        console.log(error);
      }
    },
  },
  mutations: {
    SET_PR_MODE_STATUS(state, payload) {
      state.series_data = [];
      switch (payload) {
        case "site":
          state.is_site_level = true;
          break;

        default:
          state.is_site_level = false;
          break;
      }
    },
    SET_SELECTED_SITE_LIST(state, payload) {
      state.selected_site_list = payload;
    },
    REMOVE_SITE_FROM_SELECTED_LIST(state, payload) {
      const filtered = state.selected_site_list.filter((site) => {
        return site._id !== payload;
      });
      state.selected_site_list = filtered;
    },
    SET_SITE_DEVICES(state, payload) {
      state.site_devices.push(payload);
    },
    SET_SELECTED_TIMEFRAME(state, payload) {
      state.selected_timeframe = payload;
    },
    SET_SELECTED_TIMERANGE(state, payload) {
      state.selected_timerange = payload;
    },
    SET_IS_CUSTOM_TIMERANGE(state, payload) {
      state.is_custom_timerange = payload;
    },
    SET_SELECTED_DEVICE_TYPE(state, payload) {
      state.selected_device_types = payload;
    },
    SET_SELECTED_DEVICE_LIST(state, payload) {
      state.selected_device_list = payload;
    },
    REMOVE_DEVICE_FROM_SELECTED_LIST(state, payload) {
      const filtered = state.selected_device_list.filter((device) => {
        return device._id !== payload;
      });

      state.selected_device_list = filtered;
      if (filtered.length == 0) {
        // IF : array after filter device out is empty
        state.selected_device_data = [];
        state.selected_device_types = [];
      } else {
        // ELSE : array after filter device out is not empty
        // Distinct : type from remaining devices (filtered array)
        const filtered_type_group = filtered.reduce(
          (types, cur) =>
            types.includes(cur.type) ? types : types.concat(cur.type),
          []
        );
        // Filter : remove unrelated selected params from remaining devices (filtered array)
        state.selected_device_data = state.selected_device_data.filter(
          (device) => {
            return filtered_type_group.find(
              (type) => device.device_type == type
            );
          }
        );
      }
    },
    SET_SELECTED_DEVICE_DATA(state, payload) {
      let matched_index = state.selected_device_data.findIndex(
        (item) => item.device_type == payload.device_type
      );
      // CHECK : If payload type exist in selected device data
      if (matched_index >= 0) {
        // Replace with new data
        state.selected_device_data[matched_index] = payload;
      } else {
        // Append with new data
        state.selected_device_data.push(payload);
      }

      if (state.selected_device_data.length > 0) {
        state.selected_device_data = state.selected_device_data.filter(
          (item) => item.params.length > 0
        );
      }
    },
    CLEAR_SELECTED_DEVICE_DATA(state) {
      state.selected_device_data = [];
    },
    SET_SELECTED_DEVICE_DATA_OPTION(state, payload) {
      state.selected_device_data_option = payload;
    },
    SET_DATA_AXIS(state, payload) {
      state.series_data_axis = [];
      // Check : Is Site Level ?
      if (!state.is_site_level) {
        // CHECK : is payload empty?
        if (payload && payload.length > 0) {
          for (const data_by_type of payload) {
            const device_type = data_by_type.device_type;
            const req_tags = data_by_type.req_tags;

            let unit_list = [];
            // Find & Assign unit
            for (const tag of req_tags) {
              const unit = Helpers.getDeviceDataUnit(device_type, tag);
              if (!unit_list.includes(unit)) {
                unit_list.push(unit);
              }
            }

            // Map unit array to highchart format
            const result = unit_list.map((unit, index) => {
              let payload = {
                labels: { format: "{value} " + unit },
                title: {
                  text: "",
                },
              };
              if (index % 2 == 1) {
                payload.opposite = true;
              }
              return { unit, payload };
            });
            state.series_data_axis = state.series_data_axis.concat(result);
          }
        }
      } else {
        const site_axis_list = [
          {
            unit: "energy",
            payload: {
              labels: { format: "{value} kWh" },
              title: {
                text: "",
              },
            },
          },
          {
            unit: "irrda",
            payload: {
              labels: { format: "{value} kWh/m2" },
              title: {
                text: "",
              },
            },
          },
          {
            unit: "ratio",
            payload: {
              labels: { format: "{value} %" },
              title: {
                text: "",
              },
              opposite: true,
            },
          },
        ];
        state.series_data_axis = site_axis_list;
      }
    },
    SET_DEVICE_SERIES_DATA(state, payload) {
      // init chart series
      let series = [];
      const tz_offset = Helpers.getTimeZoneOffset();

      if (payload && payload.length > 0) {
        for (const data_by_type of payload) {
          const req_tags = data_by_type.req_tags;
          const device_data_list = data_by_type.data;
          const device_type = data_by_type.device_type;

          // CHECK : is empty request tags
          if (req_tags.length > 0) {
            // looping through all devices data
            for (const device_data of device_data_list) {
              const device_id = device_data.deviceId;
              const raw_chart_data = device_data.data;

              // Match device Id with Device Name
              const matched = state.selected_device_list.find(
                (item) => item._id === device_id
              );
              if (matched && raw_chart_data.length > 0) {
                for (const tag of req_tags) {
                  // GET : unit on each tag
                  const unit = Helpers.getDeviceDataUnit(device_type, tag);
                  const found_axis_index = state.series_data_axis.findIndex(
                    (axis) => axis.unit === unit
                  );

                  // CONVERT : mapping array to high chart data format
                  let line_data = {
                    yAxis: found_axis_index,
                    name: matched.name + " [" + tag + "]",
                    custom: {
                      tag,
                      device_type,
                    },
                  };
                  line_data.data = device_data.data.map((item) => {
                    const datetime = new Date(item.date);
                    const unixtime = datetime.getTime() - tz_offset;
                    const value = item[tag] !== null ? item[tag] : null;
                    return [unixtime, value];
                  });
                  // END - CONVERT : mapping array to high chart data format

                  if (state.selected_timeframe === "day") {
                    const params = tag.split("_");
                    line_data.stack = matched.name + "_" + params[0];
                    switch (tag) {
                      case "ENETO_TOTAL":
                      case "ENEEX_TOTAL":
                      case "ENEIM_TOTAL":
                        line_data.type = "spline";
                        break;

                      default:
                        line_data.type = "column";
                        break;
                    }
                  }

                  // Appended to chart series
                  series.push(line_data);
                }
              }
            }
          }
        }
      }

      state.series_data = series;
    },
    SET_SITE_SERIES_DATA(state, payload) {
      let series = [];
      const tz_offset = Helpers.getTimeZoneOffset();

      for (const site of payload) {
        // Match Site ID with Site Name
        const found = state.selected_site_list.find(
          (existing) => existing._id === site.siteId
        );

        if (found && site.data.length > 0) {
          const keys = ["energy", "irrda", "ratio"];
          for (const key of keys) {
            const found_axis_index = state.series_data_axis.findIndex(
              (axis) => axis.unit === key
            );

            let line_data = {
              yAxis: found_axis_index,
              name: found.siteName + " [" + key + "]",
              custom: {
                tag: key,
              },
            };

            // mapping array to high chart data format
            line_data.data = site.data.map((data_item) => {
              const datetime = new Date(data_item.date);
              const unixtime = datetime.getTime() - tz_offset;
              const value = data_item[key] ? data_item[key] : null;
              return [unixtime, value];
            });

            switch (key) {
              case "energy":
                line_data.type = "column";
                break;

              default:
                line_data.type = "spline";
                break;
            }

            // Appended to chart series
            series.push(line_data);
          }
        }
      }

      state.series_data = series;
    },
    CLEAR_OLD_SERIES_DATA(state) {
      state.series_data = [];
    },
    SHOW_EMPTY_DATA_LIST_POPUP(state, payload) {
      const device_names = payload.map((item) => item.name);
      state.show_popup_empty_data_list = true;
      state.content_popup_empty_data_list = device_names;
    },
    HIDE_EMPTY_DATA_LIST_POPUP(state) {
      state.show_popup_empty_data_list = false;
    },
  },
};
