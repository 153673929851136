import dayjs from "dayjs";
import store from "@/store";
import DATA_UNITS from "@/config/data_units";
import DEVICE_SUBTYPES from "@/config/device_subtypes";

export default {
  isTokenExist: function () {
    const token = store.state.access_token;
    return token && token != "";
  },
  isMachineType(type) {
    return DEVICE_SUBTYPES.mch.includes(type);
  },
  titleCase: function (str) {
    var splitStr = str.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      // You do not need to check if i is larger than splitStr length, as your for does that for you
      // Assign it back to the array
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(" ");
  },
  generateQueryString: function (filter_object) {
    // Check is Filter objet != null or undefined
    if (filter_object) {
      const filter_keys = Object.keys(filter_object);
      // Check is Filter objet is not empty
      if (filter_keys.length > 0) {
        const query_string =
          "?" +
          filter_keys
            .filter((key) => filter_object[key] != null) // Exclude null or undefined values
            .map((key) => {
              const value = filter_object[key];
              if (value instanceof Date) {
                return key + "=" + value.toISOString(); // Preserve date as ISO string
              }
              return key + "=" + value;
            })
            .join("&");
        return query_string;
      }
    }
    return "";
  },
  getSiteName: function (site_id) {
    let matched;
    const sites = store.state.site.site_list;
    if (sites.length > 0) {
      matched = sites.find((item) => item._id === site_id);
    }
    return matched ? matched.siteName : "N/A";
  },
  getDeviceType: function (device_type) {
    if (device_type) {
      switch (device_type) {
        case "BME":
        case "LME":
        case "GME":
        case "MET":
          return "meter";

        case "RTD":
        case "PRE":
        case "FLM":
        case "SEN":
          return "sensor";

        default:
          return device_type.toLowerCase();
      }
    }
    return "-";
  },
  getDeviceSubType: function (device) {
    if (device.properties) {
      return device.properties.type ? device.properties.type : null;
    }
    return null;
  },
  getDeviceDataUnit: function (device_type, param) {
    device_type = this.isMachineType(device_type) ? "mch" : device_type;
    const unit = DATA_UNITS[param];

    if (unit) {
      switch (typeof unit) {
        case "string":
          return unit;
        default:
          return unit[device_type];
      }
    }
    return "";
  },
  isCurrentDate: function (value) {
    if (!value) return "";

    const format = "DDMMYYYY";
    const alarm_date = dayjs(value).format(format);
    const current_date = dayjs(new Date()).format(format);

    return alarm_date === current_date;
  },
  dateFormatter: function (value, formatter) {
    if (!value) return "";

    if (formatter) {
      switch (formatter) {
        case "iso":
          return dayjs(value).toISOString();

        default:
          return dayjs(value).format(formatter);
      }
    } else {
      return dayjs(value).format("DD MMM");
    }
  },
  getDurationBetween: function (startDateStr, endDateStr) {
    const endDate = new Date(endDateStr);
    const startDate = new Date(startDateStr);
    const mSec = endDate.getTime() - startDate.getTime();
    let total = Math.ceil(mSec / 1000);
    let message = "";
    if (total > 24 * 60 * 60) {
      const day = Math.floor(total / 86400);
      message = message + day + "d ";
      total = total - day * 24 * 60 * 60;
    }
    if (total > 60 * 60) {
      const hour = Math.floor(total / 3600);
      message = message + hour + "h ";
      total = total - hour * 60 * 60;
    }
    if (total > 60) {
      const min = Math.floor(total / 60);
      message = message + min + "m ";
      total = total - min * 60;
    }
    return message;
  },
  getTimeShiftFromNow: function (timerange) {
    const end_time = dayjs().toISOString();
    let start_time;
    switch (timerange) {
      case "1h":
        start_time = dayjs().subtract(1, "hour").toISOString();
        break;
      case "6h":
        start_time = dayjs().subtract(6, "hour").toISOString();
        break;
      case "12h":
        start_time = dayjs().subtract(12, "hour").toISOString();
        break;

      case "24h":
        start_time = dayjs().subtract(24, "hour").toISOString();
        break;

      case "7d":
        start_time = dayjs().subtract(7, "day").toISOString();
        break;

      default:
        start_time = dayjs().subtract(30, "min").toISOString();
        break;
    }
    return { start_time, end_time };
  },
  getOneHourTimeShift: function (date, option) {
    switch (option) {
      case "add":
        return dayjs(date).add(4, "hour").toISOString();

      default:
        return dayjs(date).subtract(4, "hour").toISOString();
    }
  },
  getTimeZoneOffset: function () {
    const current_date = new Date();
    const tz_offset = current_date.getTimezoneOffset() * 60 * 1000;

    return tz_offset;
  },
  toThousandSeparator(value, decimal) {
    return (value * 1).toFixed(decimal).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  },
  // TOOLTIP
  generateChartTooltipContent: function (points) {
    let date,
      desc = "";
    const tz_offset = this.getTimeZoneOffset();

    for (const point of points) {
      let device_type;
      if (this.isMachineType(point.series.options.custom.device_type)) {
        device_type = "mch";
      } else {
        device_type = point.series.options.custom.device_type;
      }
      const tag_param = point.series.options.custom.tag;

      // Get Unit from Device Data Param
      const unit = this.getDeviceDataUnit(device_type, tag_param);

      // Convert Values to String with 2 decimal and thousand separator
      const value_str = point.y
        .toFixed(2)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      // Convert Unix timestamp to Date Format
      date = this.dateFormatter(point.x + tz_offset, "DD/MMM/YYYY HH:mm");

      desc +=
        "<span style='color:" +
        point.color +
        "'>\u25CF</span> " +
        point.series.name +
        " : <b>" +
        value_str +
        unit +
        "</b></br>";
    }
    return date + "</br>" + desc;
  },
  generateChartTooltipSiteContent: function (points) {
    let date,
      desc = "";
    const tz_offset = this.getTimeZoneOffset();

    for (const point of points) {
      // Get Unit from Device Data Param
      const pr_tags = [
        {
          tag: "energy",
          unit: "kWh",
        },
        {
          tag: "irrda",
          unit: "kWh/m2",
        },
        {
          tag: "ratio",
          unit: "%",
        },
      ];
      const tag_param = point.series.options.custom.tag;

      const matched = pr_tags.find((item) => item.tag === tag_param);
      // Convert Values to String with 2 decimal and thousand separator
      const value_str = point.y
        .toFixed(2)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      // Convert Unix timestamp to Date Format
      date = this.dateFormatter(point.x + tz_offset, "DD/MMM/YYYY HH:mm");

      desc +=
        "<span style='color:" +
        point.color +
        "'>\u25CF</span> " +
        point.series.name +
        " : <b>" +
        value_str +
        matched.unit +
        "</b></br>";
    }
    return date + "</br>" + desc;
  },
  generateChartPredictTooltipContent: function (points) {
    let date,
      desc = "";
    const tz_offset = this.getTimeZoneOffset();

    for (const point of points) {
      // Get Unit from Device Data Param
      const unit = "kW";

      // Convert Values to String with 2 decimal and thousand separator
      const value_str = point.y
        .toFixed(2)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      // Convert Unix timestamp to Date Format
      date = this.dateFormatter(point.x + tz_offset, "DD/MMM/YYYY HH:mm");

      desc +=
        "<span style='color:" +
        point.color +
        "'>\u25CF</span> " +
        point.series.name +
        " : <b>" +
        value_str +
        unit +
        "</b></br>";
    }
    return date + "</br>" + desc;
  },
};
