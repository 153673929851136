import HttpRequest from "./HttpRequest";
import Helpers from "@/utils/Helpers";

class DeviceApiProvider extends HttpRequest {
  constructor() {
    super();
  }

  async requestGetDeviceList(query_string) {
    // let query_string = Helpers.generateQueryString(filters);
    const request_url = "/care/devices" + query_string;
    const data = await this.get(request_url);
    return data;
  }

  async requestGetDeviceData(filters, time_frame) {
    let query_string = Helpers.generateQueryString(filters);

    let request_url = "/care/data/energy/daily" + query_string;
    let response_data;
    switch (time_frame) {
      case "raw":
        request_url = "/care/data/raw" + query_string;
        response_data = await this.get(request_url);
        return response_data.raw;

      case "avg":
        request_url = "/care/data/average" + query_string;
        response_data = await this.get(request_url);
        return response_data.average;

      case "hour":
        request_url = "/care/data/energy/hourly" + query_string;
        response_data = await this.get(request_url);
        return response_data.energy;

      default:
        response_data = await this.get(request_url);
        return response_data.energy;
    }
  }

  async requestGetSitePerformanceRatioData(filters) {
    let query_string = Helpers.generateQueryString(filters);
    const request_url = "/care/data/inverter/perfRatio" + query_string;
    const data = await this.get(request_url);
    return data;
  }
}

export default DeviceApiProvider;
