import Helpers from "@/utils/Helpers";

export default {
  state: {
    default_chart: {
      chart: {
        height: 700,
        type: "spline",
        zoomType: "x",
        animation: true,
        style: {
          fontFamily: "Gotham",
        },
      },
      colors: ["#41A791", "#732151", "#E08C16", "#0B8EE0", "#E01716"],
      title: {
        text: "NiXT Care Monitoring Graph",
        style: {
          color: "#2c3e50",
          fontWeight: "bold",
        },
      },
      noData: {
        style: {
          fontWeight: "400",
          fontSize: "18px",
          color: "#C4C4C4",
        },
      },
      xAxis: {
        title: {
          text: "Date/Time",
        },
        type: "datetime",
      },
      yAxis: [],
      credits: {
        enabled: false,
      },
      exporting: {
        enabled: true,
      },
      series: [],
    },
    series_data: [],
    series_axis: [],
    is_chart_loading: false,
  },
  getters: {
    chart_options_v2(state) {
      let options = state.default_chart;
      options.yAxis = state.series_axis;
      options.series = state.series_data;
      options.tooltip = {
        shared: true,
        crosshairs: true,
        formatter: function () {
          return Helpers.generateChartPredictTooltipContent(this.points);
        },
      };
      return options;
    },
  },
  mutations: {
    SET_PREDICT_DATA_AXIS(state) {
      state.series_axis = [
        {
          labels: { format: "{value} kW" },
        },
      ];
    },
    SET_PREDICT_DATA_SERIES(state, payload) {
      let series = [];
      const tz_offset = Helpers.getTimeZoneOffset();

      for (const site of payload) {
        const site_data = site.data;
        const site_name = Helpers.getSiteName(site.siteId);

        // If data exist, Iterate through data by keys
        if (site_data.length > 0) {
          const keys = ["power", "predict"];
          for (const key of keys) {
            // init data in highchart format
            let line_data = {
              name: site_name + " [" + key + "]",
              yAxis: 0,
            };

            // assign data from payload by current key
            // mapping array to highchart data format
            line_data.data = site_data.map((item) => {
              const datetime = new Date(item.date);
              const unixtime = datetime.getTime() - tz_offset;
              const value = item[key] !== null ? item[key] : null;
              return [unixtime, value];
            });

            // determine display chart data type
            switch (key) {
              case "power":
                line_data.type = "column";
                break;

              default:
                line_data.type = "spline";
                line_data.connectNulls = true;
                line_data.marker = {
                  enabled: false,
                };
                break;
            }

            // appended to chart series
            series.push(line_data);
          }
        }
      }
      // END : Loop

      // assign to state
      state.series_data = series;
    },
    SET_CHART_LOADING_STATUS(state, payload) {
      state.is_chart_loading = payload;
    },
    RESET_CHART_STATE(state) {
      state.series_data = [];
      state.series_axis = [];
    },
  },
};
