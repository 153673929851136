import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#00b39b",
        info: "#0B8EE0",
        success: "#4CAF50",
        warning: "#E08C16",
        error: "#E01716",
        black: "#2c3e50",
      },
    },
  },
});
