import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";

Vue.use(Vuex);

// Import : Modules
import site from "./modules/site";
import data from "./modules/data";
import chart from "./modules/chart";
import alarm from "./modules/alarm";
import device from "./modules/device";

import AuthApiProvider from "@/services/AuthApiProvider";
const auth_api_provider = new AuthApiProvider();

// Setup : VuexPersistence
const vuexLocal = new VuexPersistence({
  key: "nixt_care_storage",
  storage: window.localStorage,
  reducer: (state) => ({ access_token: state.access_token }),
});

export default new Vuex.Store({
  state: {
    access_token: null,
    user_profile: null,
    show_popup_403: false,
    content_popup_403: null,
  },
  actions: {
    async redirectToActiveDirectoryUrl() {
      try {
        const response = await auth_api_provider.requestGetActiveDirectoryUrl();
        window.location.replace(response.url);
      } catch (error) {
        console.log(error);
      }
    },
    async requestCreateAccessToken({ commit }, aad_code) {
      try {
        const response = await auth_api_provider.requestCreateAccessToken({
          code: aad_code,
        });
        commit("SET_ACCESS_TOKEN", response.token);
      } catch (error) {
        console.log(error);
      }
    },
    async requestVerifyAccessToken({ commit }) {
      auth_api_provider.setAuthorizationHeadersFromLocal();
      try {
        const response = await auth_api_provider.requestVerifyAccessToken();
        commit("SET_USER_PROFILE", response);
      } catch (error) {
        if (error === 403) {
          commit("SET_403_POPUP");
        }
        console.log(error);
      }
    },
  },
  mutations: {
    SET_ACCESS_TOKEN(state, payload) {
      state.access_token = payload;
    },
    SET_USER_PROFILE(state, payload) {
      state.user_profile = payload;
    },
    SET_403_POPUP(state) {
      state.show_popup_403 = true;
      state.content_popup_403 =
        "Unauthorized! Please contact ds@banpunext.co.th to grant site access.";
    },
    HIDE_403_POPUP(state) {
      state.show_popup_403 = false;
    },
  },
  modules: { site, alarm, device, data, chart },
  plugins: [vuexLocal.plugin],
});
