import axios from "axios";

class HttpRequest {
  constructor() {
    this.axiosInstance = axios.create({
      baseURL: process.env.VUE_APP_URL,
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.VUE_APP_API_KEY,
      },
      timeout: 30000,
    });

    // Interception : Request
    this.axiosInstance.interceptors.request.use(
      function (config) {
        // Do something before request is sent
        return config;
      },
      function (error) {
        // Do something with request error
        return Promise.reject(error);
      }
    );

    // Interception : Response
    this.axiosInstance.interceptors.response.use(
      function (response) {
        console.log(response);
        const response_data = response.data;
        const responseType = response.request.responseType;
        switch (responseType) {
          case "arraybuffer":
            return response_data;

          default:
            switch (response_data.code) {
              case 0:
                return response_data.data;
              default:
                return Promise.reject(response_data.message);
            }
        }
      },
      function (error) {
        // Do something with response error
        switch (error.response.status) {
          case 401:
            window.location.replace("/login");
            return Promise.reject("Unauthorized! Please login again");

          default:
            return Promise.reject(error.response.status);
        }
      }
    );
  }

  setAuthorizationHeadersFromLocal() {
    const local_storage = JSON.parse(
      window.localStorage.getItem("nixt_care_storage")
    );
    if (local_storage && local_storage.access_token) {
      this.axiosInstance.defaults.headers.common["Authorization"] =
        "Bearer " + local_storage.access_token;
    }
  }

  setAuthorizationHeaders(access_token) {
    if (access_token) {
      this.axiosInstance.defaults.headers.common["Authorization"] =
        "Bearer " + access_token;
    }
  }

  get(methodName, data) {
    return this.axiosInstance.get(methodName, {
      params: data,
    });
  }

  downloadFile(methodName, data) {
    return this.axiosInstance.get(methodName, {
      params: data,
      responseType: "arraybuffer",
    });
  }

  create(methodName, data) {
    return this.axiosInstance.post(methodName, data);
  }

  update(methodName, data) {
    return this.axiosInstance.patch(methodName, data);
  }

  delete(methodName, param, data) {
    return this.axiosInstance.delete(methodName, {
      params: param,
      data: data,
    });
  }

  request(type, url, data) {
    let promise = null;
    switch (type) {
      case "GET":
        promise = axios.get(url, { params: data });
        break;
      case "POST":
        promise = axios.post(url, data);
        break;
      case "PATCH":
        promise = axios.patch(url, data);
        break;
      case "DELETE":
        promise = axios.delete(url, data);
        break;
      default:
        promise = axios.get(url, { params: data });
        break;
    }
    return promise;
  }
}

export default HttpRequest;
