<template>
  <v-overlay id="Error403Dialog" :opacity="0.5" :value="show_popup_403">
    <v-dialog v-model="show_popup_403" persistent max-width="420">
      <v-card class="text-center mx-auto" flat rounded="xl" color="nav_color">
        <v-card-title
          class="red--text text--darken-2 font-weight-bold justify-center"
        >
          403 ERROR
        </v-card-title>
        <v-divider class="mx-4"></v-divider>

        <v-card-text class="mt-4">
          <span class="font-weight-medium">
            {{ content_popup_403 ? content_popup_403 : "-" }}</span
          >
        </v-card-text>

        <v-divider class="mx-4"></v-divider>

        <v-card-actions class="justify-center">
          <v-btn
            dark
            rounded
            depressed
            color="primary"
            @click.prevent="hideErrorDialog()"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-overlay>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  name: "Error403Dialog",
  computed: {
    ...mapState({
      show_popup_403: (state) => state.show_popup_403,
      content_popup_403: (state) => state.content_popup_403,
    }),
  },
  methods: {
    ...mapMutations({
      hideErrorDialog: "HIDE_403_POPUP",
    }),
  },
};
</script>

<style></style>
