import Helpers from "@/utils/Helpers";

export default [
  {
    path: "/",
    beforeEnter: (to, from, next) => {
      Helpers.isTokenExist() ? next() : next("login");
    },
    component: () =>
      import(
        /* webpackChunkName: "navbar-layout" */ "@/views/layout/NavbarLayout.vue"
      ),
    children: [
      {
        path: "",
        name: "home",
        component: () =>
          import(/* webpackChunkName: "home" */ "@/views/HomeView.vue"),
      },
      {
        path: "/alarm",
        name: "alarm",
        component: () =>
          import(/* webpackChunkName: "alarm" */ "@/views/AlarmView.vue"),
      },
      {
        path: "/monitor",
        name: "monitor",
        component: () =>
          import(/* webpackChunkName: "monitor" */ "@/views/MonitorView.vue"),
      },
      {
        path: "/machine-learning",
        name: "ml",
        component: () =>
          import(/* webpackChunkName: "ml" */ "@/views/MachineLearning.vue"),
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "@/views/auth/LoginView.vue"),
  },
  {
    path: "/auth/redirect",
    name: "aad_redirect",
    component: () =>
      import(
        /* webpackChunkName: "aad_redirect" */ "@/views/auth/AadRedirect.vue"
      ),
  },
  {
    path: "*",
    name: "page-not-found",
    component: () =>
      import(/* webpackChunkName: "page-not-found" */ "@/views/NotFound.vue"),
  },
];
